$(function() {
	const $body = $('html, body');
	const $modalTrigger = $('.has-modal');
	const $modalOverlay = $('.Modal__overlay');
	const $closeModal = $('.Modal__close');
	const $modalContainer = $('.Modal');

	function openModal(e) {
		e.preventDefault();
		$body.css('overflow', 'hidden');
		$modalOverlay.addClass('Modal__overlay--is-active');
		$modalContainer.addClass('Modal--is-active');
	}

	function closeModal(e) {
		e.preventDefault();
		$body.css('overflow', 'auto');
		$modalOverlay.removeClass('Modal__overlay--is-active');
		$modalContainer.removeClass('Modal--is-active');
	}

	$modalTrigger.click(openModal);
	$modalOverlay.click(closeModal);
	$closeModal.click(closeModal);

});