$(function() {
	const $search = $('.Search');

	$('.Search__button--search').click(function() {
		$search.addClass('Search--is-active');
		$('.Search__input').focus();
	});

	$('.Search__button--cancel').click(function() {
		$search.removeClass('Search--is-active');
	});
});
